import { GraphWrapper } from '../graph-wrapper/graph-wrapper';
import cls from '../graph.module.scss';
import { TextBlock } from '../../../text-block';
import { GraphProps } from '../../model/types/graph';
import { useSelector } from 'react-redux';
import {
  getTotalNewUser,
  setDashboardTotalNewUsersAction,
  setGa4DataAction,
} from '../../../../store/ducks/dashboardState';
import { useDispatch } from 'react-redux';
import services from '../../../../services';
import { useEffect, useState } from 'react';
import { formatSocialValue } from '../../../../utils/format-social-value';
import { getUserInfo } from '../../../../store/ducks/inSiteAppState';

const CardGA4NewUsers = (props: GraphProps) => {
  const { className, ...rest } = props;
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const totalNewUsers = useSelector(getTotalNewUser);
  const [isLoading, setLoading] = useState(false);

  const getActiveUsersData = async () => {
    try {
      setLoading(true);
      const response = await services.getGA4NewUsersDataByDay(userInfo?.email || 'test@rankworks.com', '1');
      let totalValue = 0;
      const data = response?.ga4_data || [];
      for (let j = 0; j < data.length; j++) {
        totalValue += Number(data[j].newUsers);
      }
      dispatch(setDashboardTotalNewUsersAction(totalValue.toString()));
      if (data.length > 0) dispatch(setGa4DataAction(true));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    if (totalNewUsers == '-') getActiveUsersData();
  }, []);

  return (
    <GraphWrapper
      className={className}
      {...rest}
      header="New Users"
      total={totalNewUsers == '-' ? totalNewUsers : formatSocialValue(Number(totalNewUsers))}
    >
      <TextBlock className={cls.info} centred>
        {/* <span className={cls.inc}>54%</span>
        <span className={cls.text}>increase</span> */}
      </TextBlock>
    </GraphWrapper>
  );
};

export { CardGA4NewUsers };
