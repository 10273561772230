import { ReactComponent as ViewStoreIcon } from '../../../../assets/icons/icon-view-store.svg';
import { ReactComponent as DashboardIcon } from '../../../../assets/icons/icon-dashboard.svg';
import { ReactComponent as AdvertisingIcon } from '../../../../assets/icons/icon-advertising.svg';
import { ReactComponent as SocialIcon } from '../../../../assets/icons/icon-sm.svg';
import { ReactComponent as GMBIcon } from '../../../../assets/icons/icon-gmb.svg';
import { ReactComponent as SEOIcon } from '../../../../assets/icons/icon-seo.svg';
import { ReactComponent as ProfessionalServiceIcon } from '../../../../assets/icons/icon-pro.svg';
import { ReactComponent as IntegrationIcon } from '../../../../assets/icons/icon-integration.svg';
import { ReactComponent as HelpIcon } from '../../../../assets/icons/icon-help.svg';

// FIXME: strange error on `start` or `build` when importing from `router` (not work public API)
import { AppRoute } from '../../../../router/model/type/app-route';
import { MenuItem } from '../type/menu-item';

const menuList: MenuItem[] = [
  {
    path: AppRoute.IN_STORE,
    name: localStorage.getItem('cc') ? localStorage.getItem('cc') || '' : 'View store',
    Icon: ViewStoreIcon,
    id: 'VIEW_STORE',
  },
  { path: AppRoute.DASHBOARD, name: 'Dashboard', Icon: DashboardIcon, id: 'GA4' },
  {
    path: AppRoute.ADVERTISING,
    name: 'Advertising',
    Icon: AdvertisingIcon,
    id: 'ADS',
    // subMenuItems: [
    //   { path: AppRoute.ADVERTISING_GOOGLE, name: 'Google' },
    //   { path: AppRoute.ADVERTISING_META, name: 'Meta' },
    //   { path: AppRoute.ADVERTISING_YELP, name: 'Yelp' },
    //   { path: AppRoute.ADVERTISING_INFLUENCER, name: 'Influencer' },
    // ],
  },
  {
    path: AppRoute.SOCIAL,
    // redirectTo: AppRoute.SOCIAL_ANALYTICS,
    name: 'Social Media',
    Icon: SocialIcon,
    id: 'SMP',
    subMenuItems: [
      // { path: AppRoute.SOCIAL_ANALYTICS, name: "Analytics" },
      { path: AppRoute.SOCIAL_CALENDAR, name: 'Calendar' },
      { path: AppRoute.SOCIAL_NEW_POST, name: 'Build a Post' },
      // { path: AppRoute.SOCIAL_CONNECTIONS, name: 'Social Channels' },
    ],
  },
  {
    path: AppRoute.GMB,
    name: 'Google Business',
    Icon: GMBIcon,
    id: 'GMB',
    subMenuItems: [
      { path: AppRoute.GMB_CALENDAR, name: 'Calendar' },
      { path: AppRoute.GMB_NEW_POST, name: 'Build a Post' },
      { path: AppRoute.GMB_REVIEWS, name: 'Reviews' },
      { path: AppRoute.GMB_PROFILE, name: 'Profile' },
      { path: AppRoute.GMB_PLANS, name: 'Plans' },
      // { path: AppRoute.GMB_CONNECTIONS, name: 'Connection' },
    ],
  },
  {
    path: AppRoute.SEO,
    name: 'SEO',
    id: 'SEO',
    Icon: SEOIcon,
  },
  {
    path: AppRoute.SERVICES,
    name: 'Professional Services',
    Icon: ProfessionalServiceIcon,
    id: 'SERVICES',
  },
  { path: AppRoute.INTEGRATION, name: 'Integration', Icon: IntegrationIcon, id: 'INTEGRATION' },
  { path: AppRoute.GET_HELP, name: 'Help & Support', Icon: HelpIcon, id: 'HELP' },
];

const shopifyMenuList: MenuItem[] = [
  {
    path: AppRoute.IN_STORE,
    name: localStorage.getItem('cc') ? localStorage.getItem('cc') || '' : 'View store',
    Icon: ViewStoreIcon,
  },
  { path: AppRoute.DASHBOARD, name: 'Dashboard', Icon: DashboardIcon },
  {
    path: AppRoute.ADVERTISING,
    name: 'Advertising',
    Icon: AdvertisingIcon,
    // subMenuItems: [
    //   { path: AppRoute.ADVERTISING_GOOGLE, name: 'Google' },
    //   { path: AppRoute.ADVERTISING_META, name: 'Meta' },
    //   { path: AppRoute.ADVERTISING_YELP, name: 'Yelp' },
    //   { path: AppRoute.ADVERTISING_INFLUENCER, name: 'Influencer' },
    // ],
  },
  {
    path: AppRoute.SOCIAL,
    // redirectTo: AppRoute.SOCIAL_ANALYTICS,
    name: 'Social Media',
    Icon: SocialIcon,
    subMenuItems: [
      // { path: AppRoute.SOCIAL_ANALYTICS, name: "Analytics" },
      { path: AppRoute.SOCIAL_CALENDAR, name: 'Calendar' },
      { path: AppRoute.SOCIAL_NEW_POST, name: 'Build a Post' },
      // { path: AppRoute.SOCIAL_CONNECTIONS, name: 'Social Channels' },
    ],
  },
  {
    path: AppRoute.GMB,
    name: 'Google Business',
    Icon: GMBIcon,
    subMenuItems: [
      { path: AppRoute.GMB_CALENDAR, name: 'Calendar' },
      { path: AppRoute.GMB_NEW_POST, name: 'Build a Post' },
      { path: AppRoute.GMB_REVIEWS, name: 'Reviews' },
      { path: AppRoute.GMB_PROFILE, name: 'Profile' },
      // { path: AppRoute.GMB_PLANS, name: 'Plans' },
      // { path: AppRoute.GMB_CONNECTIONS, name: 'Connection' },
    ],
  },
  { path: AppRoute.INTEGRATION, name: 'Integration', Icon: IntegrationIcon },
  { path: AppRoute.GET_HELP, name: 'Help & Support', Icon: HelpIcon },
];

const mobileMenuList: MenuItem[] = [
  {
    path: AppRoute.IN_STORE,
    name: localStorage.getItem('cc') ? localStorage.getItem('cc') || '' : 'View store',
    Icon: ViewStoreIcon,
  },
  { path: AppRoute.DASHBOARD, name: 'Dashboard', Icon: DashboardIcon },
  { path: AppRoute.GMB_REVIEWS, name: 'Reviews', Icon: GMBIcon },
  { path: AppRoute.GET_HELP, name: 'Help & Support', Icon: HelpIcon },
];

const adminMenuList: MenuItem[] = [
  { path: AppRoute.ADMIN_DASHBOARD, name: 'Admin Dashboard', Icon: DashboardIcon },
  { path: AppRoute.ADMIN_CLIENTS, name: 'Clients', Icon: DashboardIcon },
  { path: AppRoute.ADMIN_USERS, name: 'Users', Icon: DashboardIcon },
];

const orgAdminMenuList: MenuItem[] = [
  { path: AppRoute.ORG_ADMIN_ACCOUNTS, name: 'Accounts', Icon: DashboardIcon },
  { path: AppRoute.ORG_ADMIN_USERS, name: 'Users', Icon: DashboardIcon },
  { path: AppRoute.ORG_ADMIN_INTEGRATIONS, name: 'Integrations', Icon: DashboardIcon },
];

export { menuList, mobileMenuList, adminMenuList, shopifyMenuList, orgAdminMenuList };
