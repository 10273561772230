import React, { memo, useState } from 'react';
import cls from './website-analytics-splash-screen.module.scss';
import { TextBlock } from '../../text-block';
import { Button } from '../../button';
import { Navigate, useNavigate } from 'react-router-dom';
import { getTagManagerScript } from '../../../utils';
import { ReactComponent as IcnCopy } from '../../../assets/icons/icn-copy.svg';
import InSiteSnackBar from '../../InSIteSnackBar/InSiteSnackBar';
import { useSelector } from 'react-redux';
import { getSelectedAccount } from '../../../store/ducks/inSiteAppState';
import { Frame } from '../../frame';
import { Modal } from '../../modal';

const SplashScreenGA4 = memo((props: any) => {
  const selectedAccount: any = useSelector(getSelectedAccount);
  const intro = `Copy the code below and paste it onto every page of your website.
  Paste this code as high in the <head> of the page as possible:`;
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(getTagManagerScript(props.gtmCode));
    setIsCopied(true);
  };
  return (
    <div className={selectedAccount?.is_ecommerce ? cls.wrapper : cls.wrapperService}>
      <div className={cls.info}>
        {/* <div className={cls.titleBlock} onClick={() => {}}>
          <span className={cls.titleBlockText}>Connect your website to Google Analytics</span>
        </div> */}
        {props?.gtmCode ? (
          <Modal sizeCustom noOverlay setOpen={() => {}} closeButton={false}>
            <Frame>
              <TextBlock header="Behavior Analytics Code">{intro}</TextBlock>
              <TextBlock className={cls.codeBlock}>
                <Button className={cls.copyButton} onClick={handleCopy} icon={IcnCopy} />
                {getTagManagerScript(props.gtmCode)}
              </TextBlock>
            </Frame>
          </Modal>
        ) : (
          // <div className={cls.textBlockText}>
          //   <div>{intro}</div>
          //   <TextBlock className={cls.codeBlock}>
          //     <Button className={cls.copyButton} onClick={handleCopy} icon={IcnCopy} />
          //     {getTagManagerScript(props.gtmCode)}
          //   </TextBlock>
          // </div>
          <Modal sizeCustom noOverlay setOpen={() => {}} closeButton={false}>
            <Frame>
              <TextBlock header="Behavior Analytics Code"></TextBlock>
              <TextBlock className={cls.codeBlock}>
                {/* <Button className={cls.copyButton} onClick={handleCopy} icon={IcnCopy} /> */}
                Can not provide the tag manager snippet right now. Please contact your service.
              </TextBlock>
            </Frame>
          </Modal>
          // <div className={cls.textBlockText}>
          //   <div> Can not provide the tag manager snippet right now. Please contact your service.</div>
          // </div>
        )}
      </div>
      <InSiteSnackBar
        alertOpen={isCopied}
        alertMessage={'Tag Manager copied to clipboard.'}
        setAlertOpen={setIsCopied}
      />
    </div>
  );
});

export { SplashScreenGA4 };
