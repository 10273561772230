import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { StarRating } from '../../components/star-rating';
import { TextBlock } from '../../components/text-block';
import cls from './competitor-list.module.scss';
import { Icon } from 'leaflet';
import CustomIcon from '../../assets/icons/icn-location.svg';
import { useEffect, useRef, useState } from 'react';
import services from '../../services';

import 'leaflet/dist/leaflet.css';
import { useDispatch } from 'react-redux';
import { getCompetitorList, setDashboardCompetitotListAction } from '../../store/ducks/dashboardState';
import { classNames } from '../../utils/class-names';
import { isMobile } from '../../utils/is-mobile';
import { useSelector } from 'react-redux';
import { getSelectedAccount } from '../../store/ducks/inSiteAppState';
interface CompetitorListProps {
  userInfo: any;
}

const customIcon = new Icon({
  iconUrl: CustomIcon,
});

const CompetitorList = (props: CompetitorListProps) => {
  const { userInfo } = props;
  const dispatch = useDispatch();
  let competitorList = useSelector(getCompetitorList);
  const selectedAccount: any = useSelector(getSelectedAccount);

  const getCompetitors = async () => {
    if (!selectedAccount?.websites || selectedAccount?.account_details?.websites) return;
    const data = {
      primary_category: selectedAccount?.primary_category || selectedAccount?.account_details?.primary_category,
      primary_service_area:
        selectedAccount?.locations[0]?.primary_service_area ||
        selectedAccount?.account_details?.locations[0]?.primary_service_area,
    };

    const response = await services.getCompetitorsByIndustryType(data);

    if (response.hasOwnProperty('id')) {
      const serpId = response.id;
      const serpResponse = await services.getCompetitorsBySerpId(serpId);
      //setCompetitorList(serpResponse.competitors);
      dispatch(setDashboardCompetitotListAction(serpResponse.competitors));
    } else {
      //setCompetitorList(response.competitors);
      dispatch(setDashboardCompetitotListAction(response.competitors));
    }
  };

  useEffect(() => {
    //if (selectedAccount && competitorList.length === 0) getCompetitors();
    if (selectedAccount) getCompetitors();
  }, [selectedAccount]);

  const mapRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  let defaultLat: number = 40.7128;
  let defaultLong: number = -73.935242;

  if (selectedAccount?.locations && selectedAccount?.locations[0]?.coordinates?.lat)
    defaultLat = parseFloat(selectedAccount?.locations[0]?.coordinates?.lat);
  if (selectedAccount?.account_details && selectedAccount?.account_details?.locations[0]?.coordinates?.lat)
    defaultLat = parseFloat(selectedAccount?.account_details?.locations[0]?.coordinates?.lat);

  if (selectedAccount?.locations && selectedAccount?.locations[0]?.coordinates?.lng)
    defaultLong = parseFloat(selectedAccount?.locations[0]?.coordinates?.lng);
  if (selectedAccount?.account_details && selectedAccount?.account_details?.locations[0]?.coordinates?.lng)
    defaultLong = parseFloat(selectedAccount?.account_details?.locations[0]?.coordinates?.lng);

  // const defaultCoordinates: any = [defaultLat, defaultLong];

  useEffect(() => {
    // Check if mapkit is loaded
    if (typeof (window as any).mapkit !== 'undefined') {
      // Initialize MapKit if not already done
      if (!(window as any).mapkit.initialized) {
        (window as any).mapkit.init({
          authorizationCallback: (done: (token: string) => void) => {
            done(
              'eyJraWQiOiJWOThITlpSTUw1IiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJYMkJBTkNQRzJUIiwiaWF0IjoxNzMzMzQ1MzUwLCJvcmlnaW4iOiJidXNpbmVzcy5yYW5rd29ya3MuY29tIn0.bZ0Jls1Vu--uDe9HrTySzZAKj498ENKbThX06Qc1qsWcWPT2oekDSxX83Jha5kGWBnfb1-c_45CijRSbrbp_lw'
            );
          },
        });
      }

      let lat = defaultLat;
      let lng = defaultLong;
      const map = new (window as any).mapkit.Map(mapRef.current);
      map.region = new (window as any).mapkit.CoordinateRegion(
        new (window as any).mapkit.Coordinate(lat, lng),
        new (window as any).mapkit.CoordinateSpan(0.1, 0.1)
      );

      // Create an annotation (marker) at the given coordinates
      const annotation = new (window as any).mapkit.MarkerAnnotation(new (window as any).mapkit.Coordinate(lat, lng), {
        title: userInfo?.company?.name || 'You',
        color: '#007AFF',
      });
      map.showItems([annotation]);

      // Add a semi-transparent overlay (div) above the map
      if (overlayRef.current) {
        overlayRef.current.style.display = 'block'; // Show overlay
      }
    }
  }, [defaultLat, defaultLong]);

  const checkIfCurrentCompany = (companyName: string) => {
    if (userInfo?.company?.name?.toLowerCase() === companyName.toLowerCase()) {
      return true;
    }
    return false;
  };

  const renderCompetitorInner = (item: any) => {
    return (
      <>
        <div className={classNames(cls.name, classNames(checkIfCurrentCompany(item.title) ? cls.currentCompany : ''))}>
          {item.title}
        </div>
        <div
          className={classNames(cls.rating, classNames(checkIfCurrentCompany(item.title) ? cls.currentCompany : ''))}
        >
          {item.rating}
        </div>
        <div className={classNames(cls.star, classNames(checkIfCurrentCompany(item.title) ? cls.currentCompany : ''))}>
          <StarRating value={item.rating} id="1" />
        </div>
        <div
          className={classNames(cls.review, classNames(checkIfCurrentCompany(item.title) ? cls.currentCompany : ''))}
        >
          ({item.review})
        </div>
      </>
    );
  };

  console.log('YES');

  const renderCompetitor = (item: any) => {
    if (!isMobile()) return renderCompetitorInner(item);

    return (
      <div className={cls.mobileWrapper} key={item.name}>
        {renderCompetitorInner(item)}
      </div>
    );
  };

  return (
    <div className={cls.competitorsWrapperRoot}>
      <TextBlock header="Here’s the top 10 competitors within your category." />
      <div className={cls.competitorsWrapper}>
        <div className={cls.competitors}>
          <div className={cls.innerWrapper}>
            <div className={cls.competitorList}>
              {competitorList.map((item: any) => renderCompetitor(item))}
              {competitorList.length === 0 && (
                <div className={cls.competitorsWrapper}>
                  <TextBlock className={cls.myRanking}>
                    We are collecting your competitor data. Please check back later.
                  </TextBlock>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <MapContainer className={cls.map} center={defaultCoordinates} zoom={13} scrollWheelZoom={false}>
          <TileLayer url="https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/reduced.day/{z}/{x}/{y}/512/png8?apiKey=xcvFjvkJ3KHSA4Q3IlQVbeqgHXvK7sa3-wlFKX7-SFc" />
          <Marker position={defaultCoordinates} icon={customIcon}>
            <Popup>{userInfo?.company?.name || 'You'}</Popup>
          </Marker>
        </MapContainer> */}
        <div style={{ position: 'relative', width: '100%' }}>
          <div className={cls.map} ref={mapRef}></div>
          <div
            ref={overlayRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(240, 248, 255, 0.5)', // Semi-transparent blue overlay
              zIndex: 999, // Ensures the overlay stays on top of the map
              display: 'none', // Hidden by default, shown on map load
              pointerEvents: 'none',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { CompetitorList };
