import { Navigate } from 'react-router-dom';

import Home from '../../../pages/home-page/home';
import { Login } from '../../../pages/login-page';
import { Pay, Success, Cancel } from '../../../pages/payment-page';
import { AccountProfile } from '../../../pages/account-profile';
import { BusinessProfile } from '../../../pages/business-profile';
import { Verification } from '../../../pages/verification-page';
import { ForgotPasswordRequest, ForgotPasswordSent } from '../../../pages/forgot-password';
import { ResetPassword } from '../../../pages/reset-password';
import Invitation from '../../../pages/invitation';
import { ExpiredToken } from '../../../pages/expired-token';
import SocialMedia from '../../../pages/social-media/social-media';
import Marketing from '../../../pages/marketing-page/marketing-page';
import Sem from '../../../pages/sem-page/sem';
import { Seo } from '../../../pages/seo-page/ui/seo';
import { Integration } from '../../../pages/integration-page';
import { BusinessRegistration } from '../../../pages/business-plans';
import { EmailValidationSuccess } from '../../../pages/email-validation-success';
import { Dashboard } from '../../../pages/dashboard-page';
import { GetHelp } from '../../../pages/get-help';
import { SocialCalendar } from '../../../pages/social-calendar';
import Admin from '../../../pages/admin-page/Admin';
import { SocialConnection } from '../../../pages/social-connection';
import { GMBCalendar } from '../../../pages/gmb-calendar';
import { GMBProfile } from '../../../pages/gmb-profile/';
import { GMBPlans } from '../../../pages/gmb-plans';
import { ExecutiveDashboard } from '../../../pages/executive-dashboard';
import DashboardById from '../../../pages/dashboard-by-id/dashboard-by-id';
import { Registration } from '../../../pages/registration-page';
import { InStore } from '../../../pages/in-store';
import { SocialAnalytics } from '../../../pages/social-analytics';
import { GMBReview } from '../../../pages/gmb-review';
import { GMBBuildPost } from '../../../pages/gmb-build-post';
import { SocialBuildPost } from '../../../pages/social-build-post';
import { GMBConnection } from '../../../pages/gmb-connection';
import { GMBDashboard } from '../../../pages/gmb-dashboard';
import ClosePage from '../../../pages/close-page';
// FIXME: DummyImagePage is not a real page
// TODO: Replace DummyImagePage with real pages
import {
  AdvertiseHomePage,
  GoogleAdvertise,
  MetaAdvertise,
  YelpAdvertise,
  InfluencerAdvertise,
  SEOMetrics,
  SEOPlans,
  SEOSiteAnalyzer,
  ProfessionalConsultingServices,
  ProfessionalGraphicsServices,
  ProfessionalWebDesignServices,
} from '../../../pages/dummy-image-page/DummyImagePage';

import { AppRoute } from '../type/app-route';
import { AdminCLients } from '../../../pages/admin-clients';
import { AdminUsers } from '../../../pages/admin-users';
import { WebDesignService } from '../../../pages/web-design-service';
import { AdDashboard } from '../../../pages/ad-dashboard';
import { ContractPage } from '../../../pages/contract-page/ui/contract';
import { PluginDashboard } from '../../../pages/plugin-dashboard/plugin-dashboard';
import GoogleAdsAccountSelectionPage from '../../../pages/google-ads-account-list';
import { OrgAdminAccounts } from '../../../pages/org-admin/accounts';
import { OrgAdminUsers } from '../../../pages/org-admin/users';
import { BusinessRegistrationModal } from '../../../components/business-registration-modal/business-registration-modal';
import { BusinessPlans } from '../../../pages/business-plans/ui/business-plans';
import OrgAdminIntegrations from '../../../pages/org-admin/OrgAdminIntegrations/OrgAdminIntegrations';
import GMBAccountSelectionPage from '../../../pages/google-business-account-list';

interface RouteConfig {
  path: AppRoute;
  id?: any;
  element: React.ReactElement;
}

const publicRoteList: Array<RouteConfig> = [
  { path: AppRoute.LOGIN, element: <Login /> },
  { path: AppRoute.REGISTRATION, element: <Registration /> },
  { path: AppRoute.VERIFICATION, element: <Verification /> },
  { path: AppRoute.PASSWORD_RESET, element: <ResetPassword headerText={'Change Password'} /> },
  { path: AppRoute.INVITATION, element: <Invitation headerText={'Invitation'} /> },
  { path: AppRoute.FORGOT_PASSWORD, element: <ForgotPasswordRequest /> },
  { path: AppRoute.FORGOT_PASSWORD_SENT, element: <ForgotPasswordSent /> },
  { path: AppRoute.EMAIL_VALIDATION_SUCCESS, element: <EmailValidationSuccess /> },
  { path: AppRoute.EXPIRED, element: <ExpiredToken /> },
  // { path: AppRoute.BUSINESS, element: <BusinessRegistrationModal /> },
  // { path: AppRoute.BUSINESS_PLANS, element: <BusinessPlans /> },
  // { path: AppRoute.BUSINESS_PAYMENT, element: <BusinessPlans /> },
  { path: AppRoute.PLUGINS, element: <PluginDashboard /> },
  { path: AppRoute.ALL, element: <Navigate to={AppRoute.LOGIN} /> },
];

const allRouteList: Array<RouteConfig> = [
  { path: AppRoute.PAY, element: <Pay /> },
  { path: AppRoute.ACCOUNT_PROFILE, element: <AccountProfile /> },
  { path: AppRoute.BUSINESS_PROFILE, element: <BusinessProfile /> },
  { path: AppRoute.BASIC_PLAN_PAYMENT, element: <BusinessProfile /> },
  // { path: AppRoute.DASHBOARD, element: <Dashboard /> },
  { path: AppRoute.VERIFICATION, element: <Verification /> },
  { path: AppRoute.PASSWORD_RESET, element: <ResetPassword headerText={'Change Password'} /> },
  { path: AppRoute.INVITATION, element: <Invitation headerText={'Invitation'} /> },
  { path: AppRoute.FORGOT_PASSWORD, element: <ForgotPasswordRequest /> },
  { path: AppRoute.FORGOT_PASSWORD_SENT, element: <ForgotPasswordSent /> },
  { path: AppRoute.EMAIL_VALIDATION_SUCCESS, element: <EmailValidationSuccess /> },
  { path: AppRoute.BUSINESS, element: <BusinessRegistrationModal /> },
  { path: AppRoute.BUSINESS_PLANS, element: <BusinessPlans /> },
  { path: AppRoute.BUSINESS_PAYMENT, element: <BusinessPlans /> },
  { path: AppRoute.EXPIRED, element: <ExpiredToken /> },
  // not used
  { path: AppRoute.MARKETING, element: <Marketing /> },
  { path: AppRoute.SOCIAL_MEDIA_NEW_POST, element: <SocialMedia /> },
  { path: AppRoute.SOCIAL_MEDIA, element: <SocialMedia /> },
  { path: AppRoute.SEM, element: <Sem /> },
  { path: AppRoute.HOME, element: <Home /> },
  //
  { path: AppRoute.PAYMENT_SUCCESS, element: <Success /> },
  { path: AppRoute.PAYMENT_CANCEL, element: <Cancel /> },
  // menu
  // { path: AppRoute.IN_STORE, element: <InStore /> },
  { path: AppRoute.DASHBOARD, id: 'GA4', element: <Dashboard /> },
  { path: AppRoute.ADVERTISING, id: 'ADS', element: <AdDashboard /> },
  { path: AppRoute.ADVERTISING_GOOGLE, element: <GoogleAdvertise /> },
  { path: AppRoute.ADVERTISING_META, element: <MetaAdvertise /> },
  { path: AppRoute.ADVERTISING_YELP, element: <YelpAdvertise /> },
  { path: AppRoute.ADVERTISING_INFLUENCER, element: <InfluencerAdvertise /> },
  { path: AppRoute.SOCIAL, id: 'SMP', element: <SocialAnalytics /> },
  //{ path: AppRoute.SOCIAL_CONNECTIONS, element: <SocialConnection /> },
  // { path: AppRoute.SOCIAL_ANALYTICS, element: <SocialAnalytics /> },
  { path: AppRoute.SOCIAL_NEW_POST, id: 'SMP', element: <SocialBuildPost /> },
  { path: AppRoute.SOCIAL_CALENDAR, id: 'SMP', element: <SocialCalendar /> },
  { path: AppRoute.GMB, id: 'GMB', element: <GMBDashboard /> },
  { path: AppRoute.GMB_CALENDAR, id: 'GMB', element: <GMBCalendar /> },
  { path: AppRoute.GMB_NEW_POST, id: 'GMB', element: <GMBBuildPost /> },
  { path: AppRoute.GMB_PROFILE, id: 'GMB', element: <GMBProfile /> },
  { path: AppRoute.GMB_REVIEWS, id: 'GMB', element: <GMBReview /> },
  { path: AppRoute.GMB_PLANS, id: 'GMB', element: <GMBPlans /> },
  { path: AppRoute.GMB_PAYMENT, id: 'GMB', element: <GMBPlans /> },
  { path: AppRoute.GMB_CONNECTIONS, id: 'GMB', element: <GMBConnection /> },
  { path: AppRoute.SEO, id: 'SEO', element: <Seo /> },
  { path: AppRoute.SEO_PAYMENT, id: 'SEO', element: <Seo /> },
  { path: AppRoute.SEO_METRICS, id: 'SEO', element: <SEOMetrics /> },
  { path: AppRoute.SEO_ANALYSER, id: 'SEO', element: <SEOSiteAnalyzer /> },
  { path: AppRoute.SEO_PLANS, id: 'SEO', element: <SEOPlans /> },
  { path: AppRoute.SERVICES, element: <WebDesignService /> },
  { path: AppRoute.SERVICES_GRAPHICS, element: <ProfessionalGraphicsServices /> },
  { path: AppRoute.SERVICES_DESIGN, element: <ProfessionalWebDesignServices /> },
  { path: AppRoute.SERVICES_CONSULTING, element: <ProfessionalConsultingServices /> },
  { path: AppRoute.INTEGRATION, element: <Integration /> },
  { path: AppRoute.GET_HELP, element: <GetHelp /> },
  { path: AppRoute.CONTRACT, element: <ContractPage /> },

  { path: AppRoute.EXECUTIVE_DASHBOARD, id: 'GA4', element: <ExecutiveDashboard /> },
  { path: AppRoute.CLOSE_PAGE, element: <ClosePage /> },
  { path: AppRoute.GOOGLE_ADS_CUSTOMER_ACCOUNTS, element: <GoogleAdsAccountSelectionPage /> },
  { path: AppRoute.GMB_CUSTOMER_ACCOUNTS, element: <GMBAccountSelectionPage /> },
  { path: AppRoute.ORG_ADMIN_ACCOUNTS, element: <OrgAdminAccounts /> },
  { path: AppRoute.ORG_ADMIN_USERS, element: <OrgAdminUsers /> },
  { path: AppRoute.ORG_ADMIN_INTEGRATIONS, element: <OrgAdminIntegrations /> },
  { path: AppRoute.ALL, element: <Navigate to={AppRoute.DASHBOARD} /> },
];

const mobileRouteList: Array<RouteConfig> = [
  { path: AppRoute.LOGIN, element: <Dashboard /> },
  { path: AppRoute.VERIFICATION, element: <Verification /> },
  { path: AppRoute.PASSWORD_RESET, element: <ResetPassword headerText={'Change Password'} /> },
  { path: AppRoute.FORGOT_PASSWORD, element: <ForgotPasswordRequest /> },
  { path: AppRoute.FORGOT_PASSWORD_SENT, element: <ForgotPasswordSent /> },
  { path: AppRoute.EMAIL_VALIDATION_SUCCESS, element: <EmailValidationSuccess /> },
  { path: AppRoute.BUSINESS, element: <BusinessRegistration /> },
  { path: AppRoute.BUSINESS_PAYMENT, element: <BusinessRegistration /> },
  { path: AppRoute.EXPIRED, element: <ExpiredToken /> },

  { path: AppRoute.PAYMENT_SUCCESS, element: <Success /> },
  { path: AppRoute.PAYMENT_CANCEL, element: <Cancel /> },

  { path: AppRoute.DASHBOARD, element: <Dashboard /> },
  { path: AppRoute.GMB_REVIEWS, element: <GMBReview /> },
  { path: AppRoute.GET_HELP, element: <GetHelp /> },

  { path: AppRoute.ALL, element: <Dashboard /> },
];

const adminRouteList: Array<RouteConfig> = [
  { path: AppRoute.LOGIN, element: <Admin /> },
  { path: AppRoute.ACCOUNT_PROFILE, element: <AccountProfile /> },
  { path: AppRoute.PASSWORD_RESET, element: <ResetPassword headerText={'Change Password'} /> },
  { path: AppRoute.EXPIRED, element: <ExpiredToken /> },
  // { path: AppRoute.EXECUTIVE_DASHBOARD, element: <ExecutiveDashboard /> },
  // { path: AppRoute.DASHBOARD_BY_ID, element: <DashboardById /> },

  // { path: AppRoute.HOME, element: <Admin /> },
  { path: AppRoute.ADMIN_DASHBOARD, element: <Admin /> },
  { path: AppRoute.ADMIN_CLIENTS, element: <AdminCLients /> },
  { path: AppRoute.ADMIN_USERS, element: <AdminUsers /> },
  { path: AppRoute.ALL, element: <Navigate to={AppRoute.ADMIN_DASHBOARD} /> },
];

const orgAdminRouteList: Array<RouteConfig> = [
  { path: AppRoute.ORG_ADMIN_ACCOUNTS, element: <OrgAdminAccounts /> },
  { path: AppRoute.ORG_ADMIN_USERS, element: <OrgAdminUsers /> },
  { path: AppRoute.ORG_ADMIN_INTEGRATIONS, element: <OrgAdminIntegrations /> },
  { path: AppRoute.ALL, element: <Navigate to={AppRoute.ORG_ADMIN_ACCOUNTS} /> },
];

export { publicRoteList, allRouteList, mobileRouteList, adminRouteList, orgAdminRouteList };
